<template>
  <v-dialog v-model="dialog" max-width="400px" persistent>
    <v-card>
      <v-toolbar
        height="80"
        :color="error ? 'error' : 'primary'"
        dark
        class="mb-2"
      >
        <v-toolbar-title style="fontSize:20px;fontWeight:bold">
          {{ formTitle }}
        </v-toolbar-title>
      </v-toolbar>
      <v-card-text>
        <v-slide-y-transition v-if="showSignin">
          <v-form ref="form" v-model="valid">
            <v-row class="fill-height" align="center" justify="center">
              <v-col cols="12" class="pa-0">
                <v-text-field
                  v-model="loginForm.email"
                  :rules="emailRules"
                  label="البريد الإلكتروني"
                  prepend-icon="mdi-email"
                  required
                ></v-text-field>
              </v-col>

              <v-col cols="12" class="pa-0">
                <v-text-field
                  v-model="loginForm.password"
                  :type="showPassword ? 'text' : 'password'"
                  label="كلمة المرور"
                  prepend-icon="mdi-lock"
                  :append-icon="showPassword ? 'mdi-eye' : 'mdi-eye-off'"
                  @click:append="showPassword = !showPassword"
                  required
                ></v-text-field>
              </v-col>
            </v-row>
            <!-- <div style="display:flex;justifyContent:flex-end">
              <div>
                <span style="fontSize:14px" class="mx-1"
                  >Already Have Account?</span
                >
                <v-btn
                  class="pa-0"
                  text
                  color="primary"
                  @click="
                    showSignin = false;
                    showSignup = true;
                  "
                  >Sign Up</v-btn
                >
              </div>
            </div> -->
          </v-form>
        </v-slide-y-transition>

        <v-slide-y-reverse-transition v-if="showSignup">
          <v-form ref="form" v-model="valid">
            <v-row class="fill-height" align="center" justify="center">
              <v-col cols="12" class="pa-0">
                <v-text-field
                  v-model="signupForm.first_name"
                  :rules="textRules"
                  label="الاسم الأول"
                  prepend-icon="mdi-account"
                  required
                ></v-text-field>
              </v-col>

              <v-col cols="12" class="pa-0">
                <v-text-field
                  v-model="signupForm.last_name"
                  :rules="textRules"
                  label="الاسم الأخير"
                  prepend-icon="mdi-lock"
                  required
                ></v-text-field>
              </v-col>

              <v-col cols="12" class="pa-0">
                <v-text-field
                  v-model="signupForm.email"
                  :rules="emailRules"
                  label="البريد الإلكتروني"
                  prepend-icon="mdi-email"
                  required
                ></v-text-field>
              </v-col>

              <v-col cols="12" class="pa-0">
                <v-text-field
                  v-model="signupForm.password"
                  :rules="textRules"
                  :type="showPassword ? 'text' : 'password'"
                  label="كلمة المرور"
                  prepend-icon="mdi-lock"
                  :append-icon="showPassword ? 'mdi-eye' : 'mdi-eye-off'"
                  @click:append="showPassword = !showPassword"
                  required
                ></v-text-field>
              </v-col>

              <v-col cols="12" class="pa-0">
                <v-text-field
                  v-model="signupForm.confirmPassword"
                  :rules="confirmPasswordRules"
                  :type="showPassword ? 'text' : 'password'"
                  label="تأكيد كلمة المرور"
                  prepend-icon="mdi-lock"
                  :append-icon="showPassword ? 'mdi-eye' : 'mdi-eye-off'"
                  @click:append="showPassword = !showPassword"
                  required
                ></v-text-field>
              </v-col>
            </v-row>
            <div style="display:flex;justifyContent:flex-end">
              <div>
                <span style="fontSize:14px" class="mx-1">have an account?</span>
                <v-btn
                  class="pa-0"
                  text
                  color="primary"
                  @click="
                    showSignin = true;
                    showSignup = false;
                  "
                  >Sing In</v-btn
                >
              </div>
            </div>
          </v-form>
        </v-slide-y-reverse-transition>

        <!-- <v-form ref="form" v-model="valid">
          <v-row class="fill-height" align="center" justify="center">
            <v-col cols="12">
              <v-text-field
                v-model="loginForm.email"
                :rules="textRules"
                label="Email"
                prepend-icon="mdi-account"
                required
              ></v-text-field>

            </v-col>

            <v-col cols="12">
              <v-text-field
                v-model="loginForm.password"
                type="password"
                :rules="textRules"
                label="Password"
                prepend-icon="mdi-lock"
                required
              ></v-text-field>

            </v-col>
          </v-row>
          <div style="display:flex;justifyContent:flex-end">
            <v-btn class="pa-0" text color="primary">create an account</v-btn>
          </div>
        </v-form> -->
      </v-card-text>
      <v-card-actions style="display:flex;justifyContent:center">
        <!-- <v-btn color="error darken-1" @click="close">
          إلغاء
        </v-btn> -->
        <v-btn color="blue darken-1" @click="submit">
          <v-progress-circular
            class="mr-2"
            color="primary"
            indeterminate
            size="25"
            v-if="submitted"
          ></v-progress-circular>
          إرسال
        </v-btn>
      </v-card-actions>
    </v-card>

    <!-- snackbar -->
    <v-snackbar
      v-model="snackbar"
      absolute
      top
      :multi-line="true"
      :color="snackbarColor"
    >
      {{ snackbarMsg }}
      <template v-slot:action="{ attrs }">
        <v-btn color="white" text v-bind="attrs" @click="snackbar = false">
          Close
        </v-btn>
      </template>
    </v-snackbar>

    <!-- <v-snackbar v-model="snackbar" absolute top :multi-line="true">
      {{ error }}

      <template v-slot:action="{ attrs }">
        <v-btn color="red" text v-bind="attrs" @click="snackbar = false">
          Close
        </v-btn>
      </template>
    </v-snackbar> -->
  </v-dialog>
</template>

<script>
// import ApiService from "@/services/api.service";
import { mapActions, mapGetters } from "vuex";

export default {
  props: ["record", "dialog"],
  data() {
    return {
      snackbar: false,
      valid: false,
      submitted: false,
      loading: false,
      error: "",
      snackbarMsg: null,
      snackbarColor: "",
      formTitle: "تسجيل دخول",
      showSignin: true,
      showSignup: false,
      showPassword: false,

      loginForm: {
        email: null,
        password: null,
      },
      signupForm: {
        first_name: "",
        last_name: "",
        email: "",
        password: "",
        confirmPassword: "",
      },
      cycle: false,

      textRules: [(v) => !!v || "البيان مطلوب"],
      emailRules: [
        (v) => !!v || "البريد الإلكتروني مطلوب",
        (v) => {
          const pattern = /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
          return pattern.test(v) || "الإيميل غير صحيح";
        },
      ],
      // phoneRules: [
      //   (v) => !!v || 'E-mail is required',
      //   (v) =>
      //     /^[+]*[(]{0,1}[0-9]{1,4}[)]{0,1}[-\s./0-9]*$/.test(v) ||
      //     'Invalid Phone Number',
      // ],
      confirmPasswordRules: [
        (v) => !!v || "تأكيد كلمة المرور مطلوب",
        (v) => v === this.signupForm.password || "يجب تطابق كلمة المرور",
      ],

      // =================== for updating users ===================
      initialPayload: {},
    };
  },
  methods: {
    ...mapActions(["login", "signup"]),

    async submit() {
      try {
        if (this.$refs.form.validate()) {
          this.submitted = true;
          // if (this.record) {
          //   await this.updateRecord();
          // } else {
          //   // create new record ...
          //   const authLogin = await this.login(this.loginForm);
          //   console.log({ authLogin });
          // }

          if (this.showSignin) {
            const authLogin = await this.login(this.loginForm);
            console.log({ authLogin });
            this.close();
          } else if (this.showSignup) {
            delete this.signupForm["confirmPassword"];
            this.signupForm["status"] = "active";
            const authSignup = await this.signup(this.signupForm);
            console.log({ authSignup });
            if (this.userGetter.errorSingup) {
              this.snackbarMsg = this.userGetter.errorSingup;
              this.snackbar = true;
            } else {
              this.loginForm["email"] = this.signupForm.email;
              this.loginForm["password"] = this.signupForm.password;
              this.showSignup = false;
              this.showSignin = true;
            }
          }

          this.submitted = false;
          location.reload();
          const msg = this.record
            ? "record updated successfully"
            : "record inserted successfully";
          this.$emit("formSubmitted", msg);
        } else {
          this.snackbar = true;
          this.error = "please fill all required fields";
        }
      } catch (error) {
        if (error.message !== "") {
          this.error = error;
        } else {
          this.error = "sorry, something went wrong";
        }
        this.snackbar = true;
        console.log(error);
        this.submitted = false;
      }
    },
    close() {
      this.$emit("closeDialog");
    },
  },
  computed: {
    ...mapGetters(["userGetter"]),
  },

  mounted() {
    this.record && Object.assign(this.loginForm, this.record);
    Object.assign(this.initialPayload, this.record);
  },
};
</script>

<style lang="scss" scoped>
.spinner.spinner-right {
  padding-right: 3.5rem !important;
}
</style>
